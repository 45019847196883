import React, { useState } from 'react';
import Select from 'react-select';
import ukrFlag from 'assets/flags/ukraine.png';
import britFlag from 'assets/flags/britan.png';
import useLocale from 'components/useLocale';
import * as colors from 'theme/colors';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LanguageContainer, FlagImage, FlagImageContainer } from './style';

const options = [
  { value: 'uk', label: <FlagImage src={ukrFlag} /> },
  { value: 'en', label: <FlagImage src={britFlag} /> },
];

function excludeCurrentOption(selectOptions, option) {
  return selectOptions.filter(el => el.value !== option.value)
}

function Language() {
  const lan = localStorage.getItem('lan') || 'uk';
  const currentOption = options.find((option) => option.value === lan);
  const [language, setLanguage] = useState(currentOption);
  const { setLocale } = useLocale();

  const theme = useTheme();
  const largerSm = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChangelanguage = (option) => {
    setLanguage({ value: option.value, label: option.label });
    setLocale(option.value);
    localStorage.setItem('lan', option.value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
    }),
    input: (provided) => ({
      ...provided,
      color: 'transparent',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: colors.lightBlue,
    }),
  };

  return (
    <LanguageContainer>
      {largerSm ?
        <Select
          value={language}
          onChange={handleChangelanguage}
          options={excludeCurrentOption(options, language)}
          styles={customStyles}
        />
        :
        <>
          {options.map((option, index) => (
            <FlagImageContainer
              key={option.value}
              last={index === options.length-1}
              selected={option.value === language.value}
              onClick={() => handleChangelanguage(option)}
            >
              {option.label}
            </FlagImageContainer>
          ))}
        </>
      }
    </LanguageContainer>
  );
}

export default Language;
