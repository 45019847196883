export const SUCCESS_HASHTAG = 'success_hashtag';
export const SUCCESS_FOOTERCONTACT = 'success_footer_contact';
export const SUCCESS_ECOCENTERCONTACT = 'success_ecocenter_contact';
export const ERROR_HASHTAG = 'error_hashtag';
export const ERROR_FOOTERCONTACT = 'error_footer_contact';
export const ERROR_ECOCENTERCONTACT = 'error_ecocenter_contact';
export const ADD_HASHTAG = 'add_hashtag';
export const ADD_FOOTERCONTACT = 'add_footer_contact';
export const ADD_ECOCENTERCONTACT = 'add_ecocenter_contact';
export const DELETE_HASHTAG = 'delete_hashtag';
export const DELETE_FOOTERCONTACT = 'delete_footer_contact';
export const DELETE_ECOCENTERCONTACT = 'delete_ecocenter_contact';
export const EDIT_HASHTAG = 'edit_hashtag';
export const EDIT_FOOTERCONTACT = 'edit_footer_contact';
export const EDIT_ECOCENTERCONTACT = 'edit_ecocenter_contact';
