import styled from 'styled-components';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import * as colors from 'theme/colors';

export const PageContainer = styled.div`
  ${({ theme }) => `
  padding: 110px 10% 20px 10%;
  flex-grow: 1;

  ${theme.breakpoints.down("sm")} {
      padding: 110px 10px 20px 10px;
    }
  `}
`;

export const NextArticleArrow = styled(PresentToAllIcon)`
  transform: rotate(90deg);
  cursor: pointer;
`;

export const PrevArticleArrow = styled(PresentToAllIcon)`
  transform: rotate(-90deg);
  cursor: pointer;
`;

export const BackArrow = styled(ArrowBackRoundedIcon)`
  cursor: pointer;
`;

export const Bullet = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(props) =>
    props.active ? colors.LightViolet : colors.lightBlue};
  margin: 0 2px;
`;

export const ArrowImageLeft = styled.img`
    cursor: pointer;
    opacity: 0.6;
    width: 24px;
    height: 24px;
`;

export const ArrowImageRight = styled.img`
    transform: rotate(180deg);
    cursor: pointer;
    opacity: 0.6;
    width: 24px;
    height: 24px;
`;

export const ArrowsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 112px;
  width: 95%;
  box-sizing: border-box;
`;

export const ArticleContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
