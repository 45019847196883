import React from 'react';
import useTranslation from 'hooks/useTranslation';
import { mainPage } from 'strings/mainPage';
import {
  Subtitle1Bold,
  Subtitle1,
} from 'components/Typography';
import Image1 from 'assets/gallery/2.png';
import Image2 from 'assets/gallery/3.png';
import Image3 from 'assets/gallery/4.png';
import Image4 from 'assets/gallery/5.png';
import Image5 from 'assets/gallery/6.png';
import Image6 from 'assets/gallery/7.png';
import Image7 from 'assets/gallery/8.png';


import Box from '@material-ui/core/Box';

import {
  StyledImage,
	StyledLink,
} from './style';

function MobileGallery() {

  const t = useTranslation();
  const strings = t(mainPage);

  return (
    <>
			<StyledImage src={Image1}/>
			<StyledImage src={Image2}/>
			<Box padding="10px 15px">
				<Box textAlign="center">
					<Subtitle1Bold>{strings.mission}</Subtitle1Bold>
				</Box>
				<Box
					display="flex"
					width="100%"
					justifyContent="flex-end"
					paddingRight="50px"
					paddingTop="10px"
					boxSizing="border-box"
				>
					<Subtitle1>
						<StyledLink to="/eco-center">{strings.readMore}</StyledLink>
					</Subtitle1>
				</Box>
			</Box>
			<StyledImage src={Image3}/>
			<StyledImage src={Image4}/>
			<StyledImage src={Image5}/>
			<StyledImage src={Image6}/>
			<StyledImage src={Image7}/>
	</>
  );
}

export default MobileGallery;
