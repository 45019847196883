import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import {
  addHashtag,
  useContacts,
  getHashtags,
  editHashtag,
  deleteHashtag,
  getFooterContacts,
  addFooterContact,
  deleteFooterContact,
  editFooterContact,
  getEcoCenterContacts,
  addEcoCenterContact,
  deleteEcoCenterContact,
  editEcoCenterContact,
} from 'components/useContacts';
import Box from '@material-ui/core/Box';
import HashtagsForm from './HashtagsForm';
import ContactsForm from './ContactsForm';
import { Line, ContactsContainer, HashtagsContainer } from './style';
import Tabs from './Tabs';
import { TransportsContainer } from '../style';

function Contacts() {
  const [ContactState, ContactDispatch] = useContacts();

  const handleAddHashtag = async (body) => {
    addHashtag(ContactDispatch, body);
  };
  const handleAddFooterContact = async (body) => {
    addFooterContact(ContactDispatch, body);
  };
  const handleAddEcoCenterContact = async (body) => {
    addEcoCenterContact(ContactDispatch, body);
  };

  const handleEditHashtag = async (body, id) => {
    editHashtag(ContactDispatch, body, id);
  };
  const handleEditFooterContact = async (body, id) => {
    editFooterContact(ContactDispatch, body, id);
  };
  const handleEditEcoCenterContact = async (body, id) => {
    editEcoCenterContact(ContactDispatch, body, id);
  };

  const handleDeleteHashtag = (id) => {
    deleteHashtag(ContactDispatch, id);
  };
  const handleDeleteFooterContact = (id) => {
    deleteFooterContact(ContactDispatch, id);
  };
  const handleDeleteEcoCenterContact = (id) => {
    deleteEcoCenterContact(ContactDispatch, id);
  };

  useEffect(() => {
    getHashtags(ContactDispatch);
  }, []);
  useEffect(() => {
    getFooterContacts(ContactDispatch);
  }, []);
  useEffect(() => {
    getEcoCenterContacts(ContactDispatch);
  }, []);

  const { path, url, isExact } = useRouteMatch();

  if (isExact) {
    return <Redirect to={`${url}/Hashtags`} />;
  }

  const ContactsPagesSwitch = (
    <Switch>
      <Route path={`${path}/Hashtags`}>
        <HashtagsContainer>
          <HashtagsForm addHashtag={handleAddHashtag} />
          <Line />
          {ContactState.hashtags.map((hashtag) => (
            <Box key={hashtag.id}>
              <HashtagsForm
                editHashtag={handleEditHashtag}
                hashtag={hashtag}
                deleteHashtag={handleDeleteHashtag}
              />
            </Box>
          ))}
        </HashtagsContainer>
      </Route>
      <Route path={`${path}/email&phNumbers`}>
        <ContactsContainer>
          <ContactsForm addContact={handleAddFooterContact} />
          <Line />
          {ContactState.footerContacts.map((contact) => (
            <Box key={contact.id}>
              <ContactsForm
                editContact={handleEditFooterContact}
                contact={contact}
                deleteContact={handleDeleteFooterContact}
              />
            </Box>
          ))}
        </ContactsContainer>
      </Route>
      <Route path={`${path}/eco-center`}>
        <ContactsContainer>
          <ContactsForm addContact={handleAddEcoCenterContact} />
          <Line />
          {ContactState.ecoCenterContacts.map((ecoContact) => (
            <Box key={ecoContact.id}>
              <ContactsForm
                editContact={handleEditEcoCenterContact}
                contact={ecoContact}
                deleteContact={handleDeleteEcoCenterContact}
              />
            </Box>
          ))}
        </ContactsContainer>
      </Route>
    </Switch>
  );

  return (
    <TransportsContainer>
      <Tabs url={url} path={path} />
      {ContactsPagesSwitch}
    </TransportsContainer>
  );
}

export default Contacts;
