import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;


function Feedbacker({
  open,
  autoHideDuration,
  severity = "success",
  feedbackMessage,
  clearFeedback,
}) {
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    clearFeedback();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleAlertClose}
    >
      <Alert onClose={handleAlertClose} severity={severity}>
        {feedbackMessage}
      </Alert>
    </Snackbar>
  );
}

export default Feedbacker;
