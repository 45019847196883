import * as actionTypes from './actionTypes';

const initialState = {
  hashtags: [],
  footerContacts: [],
  ecoCenterContacts: [],
  loadingHashtags: true,
  loadingFooterContacts: true,
  loadingEcoCenterContacts: true,
  errorHashtags: null,
  errorFooterContacts: null,
  errorEcoCenterContacts: null,
};

function ContactReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SUCCESS_HASHTAG: {
      return {
        ...state,
        hashtags: action.data,
        loadingHashtags: false,
        errorHashtags: null,
      };
    }
    case actionTypes.SUCCESS_FOOTERCONTACT: {
      return {
        ...state,
        footerContacts: action.data,
        loadingFooterContacts: false,
        errorFooterContacts: null,
      };
    }
    case actionTypes.SUCCESS_ECOCENTERCONTACT: {
      return {
        ...state,
        ecoCenterContacts: action.data,
        loadingEcoCenterContacts: false,
        errorEcoCenterContacts: null,
      };
    }
    case actionTypes.ADD_HASHTAG: {
      return {
        ...state,
        hashtags: [action.data, ...state.hashtags],
      };
    }
    case actionTypes.ADD_FOOTERCONTACT: {
      return {
        ...state,
        footerContacts: [action.data, ...state.footerContacts],
      };
    }
    case actionTypes.ADD_ECOCENTERCONTACT: {
      return {
        ...state,
        ecoCenterContacts: [action.data, ...state.ecoCenterContacts],
      };
    }
    case actionTypes.DELETE_HASHTAG: {
      return {
        ...state,
        hashtags: state.hashtags.filter(
          (hashtag) => hashtag.id !== action.data,
        ),
      };
    }
    case actionTypes.DELETE_FOOTERCONTACT: {
      return {
        ...state,
        footerContacts: state.footerContacts.filter(
          (footerContact) => footerContact.id !== action.data,
        ),
      };
    }
    case actionTypes.DELETE_ECOCENTERCONTACT: {
      return {
        ...state,
        ecoCenterContacts: state.ecoCenterContacts.filter(
          (ecoCenterContact) => ecoCenterContact.id !== action.data,
        ),
      };
    }
    case actionTypes.ERROR_HASHTAG: {
      return {
        ...state,
        loadingHashtags: false,
        errorHashtags: action.error,
      };
    }
    case actionTypes.ERROR_FOOTERCONTACT: {
      return {
        ...state,
        loadingFooterContacts: false,
        errorFooterContacts: action.error,
      };
    }
    case actionTypes.ERROR_ECOCENTERCONTACT: {
      return {
        ...state,
        loadingEcoCenterContacts: false,
        errorEcoCenterContacts: action.error,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default ContactReducer;

export { initialState };
