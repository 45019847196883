import styled from 'styled-components';
import Box from '@material-ui/core/Box';

export const SocialContainer = styled(Box)`
  height: 100px;
  display: flex;
  align-items: center;

  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      width: 300px;
      height: 140px;
      justify-content: space-evenly;
      flex-wrap: wrap;  
      justify-content: center;
      gap: 0 30px;
      margin: 20px 0;
    }
    ${theme.breakpoints.up('sm')} {
      justify-content: flex-end;
      flex-wrap: nowrap;
      height: 100px;
      gap: 0 0px;
      margin: 0;
    }
  `}
`;

export const SocialItemWrapper = styled(Box)`
  ${({ theme }) => `
  width: 56px;
  display: flex;
  justify-content: center;

    ${theme.breakpoints.down('md')} {
      width: 48px;
      ${theme.breakpoints.down('sm')} {
        width: 56px;
      }
    }
  `}
`;

export const SocialImage = styled.img`
  ${({ theme }) => `
    height: 40px;
    width: auto;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      height: 50px;
    }
    ${theme.breakpoints.down('md')} {
        height: 32px;

        &:hover {
          height: 40px;
        }
      ${theme.breakpoints.down('sm')} {
        height: 40px;

        &:hover {
          height: 40px;
        }
      }
    }
  `}
`;

export const SocialImageYtube = styled.img`
  ${({ theme }) => `
    height: 30px;
    width: auto;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      height: 40px;
    }
    ${theme.breakpoints.down('md')} {
        height: 24px;

        &:hover {
          height: 24px;
        }
      ${theme.breakpoints.down('sm')} {
        height: 30px;

        &:hover {
          height: 30px;
        }
      }
    }
  `}
`;

export const SocialImageInsta = styled.img`
  ${({ theme }) => `
    height: 50px;
    width: auto;
    cursor: pointer;
    transition: all 0.2s ease;
    padding-left: 0px;
    &:hover {
      height: 60px;
    }
    ${theme.breakpoints.down('md')} {
      height: 40px;

      &:hover {
        height: 40px;
      }
      ${theme.breakpoints.down('sm')} {
        height: 50px;

        &:hover {
          height: 50px;
        }
      }
    }
  `}
`;
