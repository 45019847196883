import { useContext } from 'react';

import ScrollProvider from './Provider';
import { ScrollDispatchContext, ScrollStateContext } from './Context';
import { setScrollToCategories } from './actions';

function useScrollState() {
  const context = useContext(ScrollStateContext);
  if (context === undefined) {
    throw new Error('useScrollState must be used within a ScrollProvider');
  }
  return context;
}

function useScrollDispatch() {
  const context = useContext(ScrollDispatchContext);
  if (context === undefined) {
    throw new Error('useScrollDispatch must be used within a ScrollProvider');
  }
  return context;
}

function useScroll() {
  return [useScrollState(), useScrollDispatch()];
}

export default useScroll;

export {
  ScrollProvider,
  useScrollState,
  useScrollDispatch,
  setScrollToCategories,
};
