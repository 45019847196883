import React, { useEffect } from 'react';
import {
  addMember,
  useTeam,
  getMembers,
  editMember,
  deleteMember,
} from 'components/useTeam';
import Box from '@material-ui/core/Box';
import AddTeamMemberForm from './Form';

import { TeamContainer, Line } from './style';

function Team() {
  const [teamState, teamDispatch] = useTeam();

  const handleAddMember = async (body) => {
    addMember(teamDispatch, body);
  };

  const handleEditMember = async (body, id) => {
    editMember(teamDispatch, body, id);
  };

  const handleDelete = (id) => {
    deleteMember(teamDispatch, id);
  };

  useEffect(() => {
    getMembers(teamDispatch);
  }, []);

  return (
    <TeamContainer>
      <AddTeamMemberForm addMember={handleAddMember} />
      <Line />
      {teamState.members.map((member) => (
        <Box key={member.id}>
          <AddTeamMemberForm
            editMember={handleEditMember}
            member={member}
            deleteMember={handleDelete}
          />
        </Box>
      ))}
    </TeamContainer>
  );
}

export default Team;
