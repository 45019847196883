import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useArticlesState } from 'components/useArticles';
import Article from 'components/Article';
import AddArticleForm from './AddArticleForm';
import ArticlePopup from './ArticlePopup';
import { ArticlePreviewContainer, CreateArticleContainer } from './style';

function findArticle(articles, id) {
  return articles.find((el) => el.id === id);
}

function findDraft(drafts, id) {
  return drafts.find((el) => el.id === id);
}

function CreateArticle({
  categories,
  onAddArticle,
  onAddDraft,
  onEditArticle,
  onEditDraft,
  onRemoveDraft,
  onRemoveArticle,
}) {
  const params = useParams();
  const { articles, drafts } = useArticlesState();
  const article = params.id ? findArticle(articles, params.id) : null;
  const draft = params.id ? findDraft(drafts, params.id) : null;

  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [previewValues, setPreviewValues] = useState(article);

  const handleShowPreview = (values) => {
    setPreviewValues(values);
    setPreviewVisible(true);
  };

  return (
    <CreateArticleContainer>
      <AddArticleForm
        onShowPreview={handleShowPreview}
        onAddArticle={onAddArticle}
        onAddDraft={onAddDraft}
        onEditArticle={onEditArticle}
        onEditDraft={onEditDraft}
        onRemoveArticle={onRemoveArticle}
        onRemoveDraft={onRemoveDraft}
        categories={categories}
        article={article}
        draft={draft}
      />
      <ArticlePopup
        open={isPreviewVisible}
        onClose={() => setPreviewVisible(false)}
      >
        <ArticlePreviewContainer>
          <Article articleData={previewValues} />
        </ArticlePreviewContainer>
      </ArticlePopup>
    </CreateArticleContainer>
  );
}

export default CreateArticle;
