import * as actionTypes from './actionTypes';

const initialState = {
  scrollToCategory: false,
};

function scrollReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_SCROLL_TO_CATEGORY: {
      return {
        ...state,
        scrollToCategory: action.data,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default scrollReducer;

export { initialState };
