import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import * as colors from 'theme/colors';

export const MapCategoryButton = styled(IconButton)`
  ${({ selected, theme, $color }) => `
    position: absolute !important;
    top: 0;
    left: 0;
    background: ${selected ? $color : 'white'} !important;
    margin-right: 10px !important;
    width: 40px;
    height: 40px;

    ${theme.breakpoints.down('md')} {

    }
  `}
`;

export const RoundIconButton = styled(IconButton)`
  background: ${colors.lightBlue} !important;
`;

export const RoundIconButtonInverted = styled(IconButton)`
  background: white !important;
  width: 50px;
  height: 50px;
`;

export const BlackButton = styled(Button)`
  background: #030303 !important;
  color: white !important;
  padding: 10px 30px !important;
  border-radius: 52px !important;
  width: 300px;
  height: 60px;
`;

export const GoTopButton = styled((props) => (
  <IconButton color="primary" {...props} />
))`
  position: fixed !important;
  bottom: 50px;
  right: 30px;
  z-index: 1000;
  background-color: ${colors.lightBlue} !important;
`;
