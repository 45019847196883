import React from 'react';
import Button from '@material-ui/core/Button';
import * as validation from 'helpers/validationHelper';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import CustomTextField from 'components/CustomTextField';
import { Box } from '@material-ui/core';
import { ContactsContainer, FormContainer } from './style';

const validationSchema = yup.object().shape({});

function ContactsForm({ addHashtag, hashtag, editHashtag, deleteHashtag }) {
  const handleFormSubmit = async (values, props) => {
    if (hashtag) {
      await editHashtag(values, hashtag.id);
      return;
    }

    await addHashtag(values);
    props.resetForm();
  };

  return (
    <Box>
      <ContactsContainer>
        <Formik
          validateOnChange
          initialValues={{
            name: hashtag ? hashtag.name : '',
            url: hashtag ? hashtag.url : '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          <Form>
            <FormContainer>
              <Box width="400px">
                <CustomTextField label="#Hashtag" name="name" type="text" />
              </Box>
              <Box marginLeft="10px" width="400px">
                <CustomTextField label="link" name="url" type="text" />
              </Box>

              <Box marginLeft="20px" width="200px" display="flex">
                <Button color="primary" type="submit" variant="outlined">
                  Save
                </Button>
                {hashtag && (
                  <Box marginLeft="10px">
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        deleteHashtag(hashtag.id, handleFormSubmit)
                      }
                    >
                      Delete
                    </Button>
                  </Box>
                )}
              </Box>
            </FormContainer>
          </Form>
        </Formik>
      </ContactsContainer>
    </Box>
  );
}

export default ContactsForm;
