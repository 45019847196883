import { useContext } from 'react';

import ArticlesProvider from './Provider';
import { ArticlesStateContext, ArticlesDispatchContext } from './Context';
import {
  addArticle,
  getArticles,
  editArticle,
  deleteArticle,
  addDraft,
  getDraft,
  editDraft,
  deleteDraft,
  removeSuccessMessage,
} from './actions';

function useArticlesState() {
  const context = useContext(ArticlesStateContext);
  if (context === undefined) {
    throw new Error('useArticlesState must be used within a AriclesProvider');
  }
  return context;
}

function useArticlesDispatch() {
  const context = useContext(ArticlesDispatchContext);
  if (context === undefined) {
    throw new Error('useAriclesDispatch must be used within a AriclesProvider');
  }
  return context;
}

function useArticles() {
  return [useArticlesState(), useArticlesDispatch()];
}

export default useArticles;

export {
  ArticlesProvider,
  useArticlesState,
  useArticlesDispatch,
  addArticle,
  getArticles,
  editArticle,
  deleteArticle,
  addDraft,
  getDraft,
  editDraft,
  deleteDraft,
  removeSuccessMessage,
};
