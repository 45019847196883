export const howtogetPage = {
  uk: {
    variant: 'варіант',
    address1: 'Село Стіна, Томашпільська ТГ, Вінницька обл.',
    address2: ' 24231',
    local: 'Місцеві перевізники:',
    header: 'Доїзд громадським транспортом:',
    coords: '48.455419, 28.417462',
  },
  en: {
    variant: 'option',
    address1:
      'Stina village, Tomashpil territorial community, Vinnytsia oblast',
    address2: ' 24231',
    local: 'Local transportation:',
    header: 'How to reach with public transport:',
    coords: '48.455419, 28.417462',
  },
};
