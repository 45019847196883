import React from "react";
import { Container, MiddleLine, TopLine, DownLine } from "./style";

function HumburgerButton({ isOpen, toggleMenu }) {
  return (
    <Container onClick={toggleMenu}>
      <TopLine isOpen={isOpen} />
      <MiddleLine isOpen={isOpen} />
      <DownLine isOpen={isOpen} />
    </Container>
  );
}

export default HumburgerButton;
