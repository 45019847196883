import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import facebookLogo from 'assets/facebook.png';
import pangeyaLogo from 'assets/pangeya.png';
import yutubeLogo from 'assets/ytube.png';
import gphotosLogo from 'assets/gphotos.png';
import share from 'assets/share.png';
import insta from 'assets/insta.png';

import SharingNetworkModal from 'components/SharingNetworkModal';
import {
  SocialImage,
  SocialImageYtube,
  SocialImageInsta,
  SocialContainer,
  SocialItemWrapper,
} from './style';

function SocialLinks() {
  const [isSharingModalOpen, setSharingModalOpen] = useState(false);

  const handleOpenModal = () => setSharingModalOpen(true);
  const handleCloseModal = () => setSharingModalOpen(false);

  return (
    <>
      <SocialContainer>
        <Link href="http://pangeya.org.ua/" target="_blank">
          <SocialItemWrapper>
            <SocialImage src={pangeyaLogo} />
          </SocialItemWrapper>
        </Link>
        <Link href="https://www.facebook.com/stinaecocenter" target="_blank">
          <SocialItemWrapper>
            <SocialImage src={facebookLogo} />
          </SocialItemWrapper>
        </Link>
        <Link href="https://www.instagram.com/eco_center_stina" target="_blank">
          <SocialItemWrapper>
            <SocialImageInsta src={insta} />
          </SocialItemWrapper>
        </Link>
        <Link
          href="https://www.youtube.com/channel/UCS9k8Er19EUxesrVPbLtE_w/videos"
          target="_blank"
        >
          <SocialItemWrapper>
            <SocialImageYtube src={yutubeLogo} />
          </SocialItemWrapper>
        </Link>
        <Link
          href="https://photos.app.goo.gl/Mpfi1Xy9KbhnWj4V8"
          target="_blank"
        >
          <Box width="56px" display="flex" justifyContent="center">
            <SocialImage src={gphotosLogo} />
          </Box>
        </Link>
        <Box onClick={handleOpenModal}>
          <SocialItemWrapper>
            <SocialImage src={share} />
          </SocialItemWrapper>
        </Box>
        <SharingNetworkModal
          open={isSharingModalOpen}
          onClose={handleCloseModal}
        />
      </SocialContainer>
    </>
  );
}

export default SocialLinks;
