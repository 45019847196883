import { useContext } from 'react';

import ContactProvider from './Provider';
import { ContactDispatchContext, ContactStateContext } from './Context';
import {
  getHashtags,
  addHashtag,
  deleteHashtag,
  editHashtag,
  getFooterContacts,
  addFooterContact,
  deleteFooterContact,
  editFooterContact,
  getEcoCenterContacts,
  addEcoCenterContact,
  deleteEcoCenterContact,
  editEcoCenterContact,
} from './actions';

function useContactState() {
  const context = useContext(ContactStateContext);
  if (context === undefined) {
    throw new Error('useContactState must be used within a ContactProvider');
  }
  return context;
}

function useContactsDispatch() {
  const context = useContext(ContactDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useContactsDispatch must be used within a ContactProvider',
    );
  }
  return context;
}

function useContacts() {
  return [useContactState(), useContactsDispatch()];
}

export default useContacts;

export {
  ContactProvider,
  useContactState,
  useContactsDispatch,
  useContacts,
  getHashtags,
  addHashtag,
  deleteHashtag,
  editHashtag,
  getFooterContacts,
  addFooterContact,
  deleteFooterContact,
  editFooterContact,
  getEcoCenterContacts,
  addEcoCenterContact,
  deleteEcoCenterContact,
  editEcoCenterContact,
};
