export const routes = [
  {
    id: 1,
    name: 'Videos',
    link: `/gospodar/videos`,
  },
  {
    id: 2,
    name: 'Places',
    link: `/gospodar/places`,
  },
  {
    id: 3,
    name: 'Articles',
    link: `/gospodar/articles`,
  },
  {
    id: 4,
    name: 'Transport',
    link: `/gospodar/transport`,
  },
  {
    id: 5,
    name: 'Team',
    link: `/gospodar/team`,
  },
  {
    id: 6,
    name: 'Contacts',
    link: `/gospodar/contacts`,
  },
];
