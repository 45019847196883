import styled from 'styled-components';

export const PageContainer = styled.div`
  padding-top: 90px;
  flex-grow: 1;
`;

export const TopContainer = styled.div`
  ${({ theme }) => `
  display: flex;

  ${theme.breakpoints.down("sm")} {
      flex-direction: column;
    }
  `}
`;

export const TopContainerTextSection = styled.div`
  ${({ theme }) => `
  width: 30%;
  padding: 10px 20px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
      width: 100%;
    }
  `}
`;

export const TopContainerImageSection = styled.div`
${({ theme }) => `
  width: 70%;

  ${theme.breakpoints.down("sm")} {
      width: 100%;
    }
  `}
`;

export const TopImageContainer = styled.div``;

export const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

export const BottomContainer = styled.div`
 ${({ theme }) => `
  display: flex;
  padding: 5px;
  margin-bottom: 50px;

  ${theme.breakpoints.down("sm")} {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    }
  `}
`;

export const BottomContainerMapSection = styled.div`
${({ theme }) => `
  width: 65%;
  height: 600px;

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    height: 500px;
    }
  `}
`;

export const BottomContainerSliderSection = styled.div`
${({ theme }) => `
  width: 35%;
  height: 600px;

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    margin-top: 20px;
    height: fit-content;
    }
  `}
`;

export const ArrowImageTop = styled.img`
  width: 90px;
  height: auto;
  transform: rotate(90deg);
  cursor: pointer;
  opacity: 0.6;
`;

export const ArrowImageBottom = styled.img`
  width: 90px;
  height: auto;
  transform: rotate(-90deg);
  cursor: pointer;
  opacity: 0.6;
`;

export const SwiperContainer = styled.div`
  position: relative;
  height: 95%;
  direction: rtl;
  overflow-y: auto;
  scrollbar-color: black white;
`;

export const SwiperContainerContent = styled.div`
  direction: ltr;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const StyledButton = styled.div`
  width: 200px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 8px;
  margin-left: -20px;
  cursor: pointer;
  text-transform: capitalize;
  border: 1px solid #F5F5F5;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 0px 10px 10px 0px;
  transition: all 0.3s ease;
`;

export const TextContainer = styled.div`
  padding-bottom: 10px;
`;
