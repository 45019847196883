import styled from 'styled-components';
// import * as colors from 'theme/colors';

export const ContactsContainer = styled.div``;

export const TabContainer = styled.div``;

export const Line = styled.div`
  border-bottom: 1px solid black;
`;

export const HashtagsContainer = styled.div``;

export const FormContainer = styled.div`
  padding: 20px;
  // width: 70%;
  display: flex;
  align-items: center;
`;
