import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import VideosSlider from 'components/VideosSlider';
import HeaderLight from 'components/HeaderLight';
import SocialLinks from 'components/SocialLinks';
import MobileHeader from 'components/Mobile/Header';
import MobileGallery from 'components/MobileGallery';
import ScrollToTop from 'components/ScrollToTop';
import stripeImage from 'assets/stripe.png';
import stripeImageMobile from 'assets/mobile/stripe.png';
import loadIcon from 'assets/eco-center/load.png';
import {
  HeadingH4,
  HeadingH5,
  HeadingH6Bold,
  Subtitle1Bold,
  Body1Bold,
  Body2,
} from 'components/Typography';
import useTranslation from 'hooks/useTranslation';
import useOnScreen from 'hooks/useOnScreen';
import { ecoCenter } from 'strings/ecoCenter';
import topImage from 'assets/eco-center/top.png';
import hataImage from 'assets/eco-center/hata.png';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import ButtonsSliderMobile from 'components/Mobile/ButtonsSlider';
import { useTeam, getMembers } from 'components/useTeam';
import useSlides from './slides';
import useSlidesMobile from './slidesMobile';
import {
  useContacts,
  getEcoCenterContacts,
} from '../../components/useContacts';

import {
  PageContainer,
  TopContainer,
  TopContainerTextSection,
  TopContainerImageSection,
  TopImageContainer,
  Image,
  PersonImage,
  MiddleContainer,
  MiddleContainerText,
  MozaicSlideContainer,
  SwiperContainer,
  PeopleContainer,
  StyledCollapse,
  TeamHeadingContainer,
  StyledCollapseBody,
  ButtonsContainer,
  StyledSliderButton,
  StyledLink,
  BlackEcoButton,
} from './style';

const linkToPresentation = {
  uk: 'https://drive.google.com/file/d/15CYqJCVo_UxZtA_3UDL_jqMmup16nIdL/view?usp=sharing',
  en: 'https://drive.google.com/file/d/1z9_Ij44X2MGooFoISb8tADSaGccC4K06/view?usp=sharing',
};

function EcoCenter() {
  const swiperRef = useRef(null);
  const defaultSlideIndex = 0;
  const [currentSlide, setCurrentSlide] = useState(defaultSlideIndex);
  const slides = useSlides();
  const slidesMobile = useSlidesMobile();
  const t = useTranslation();
  const strings = t(ecoCenter);
  const [ContactState, ContactDispatch] = useContacts();

  const [teamState, teamDispatch] = useTeam();

  const visibleEcoCenter = useOnScreen(swiperRef.current);

  const theme = useTheme();
  const largerSm = useMediaQuery(theme.breakpoints.up('sm'));
  const largerMd = useMediaQuery(theme.breakpoints.up('md'));
  const sortedContacts = ContactState.ecoCenterContacts.sort((a, b) => {
    if (a.name.includes('+') && !b.name.includes('+')) {
      return -1;
    }
    if (!a.name.includes('+') && b.name.includes('+')) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    getMembers(teamDispatch);
  }, []);
  useEffect(() => {
    getEcoCenterContacts(ContactDispatch);
  }, []);

  useEffect(() => {
    if (!swiperRef) {
      return;
    }
    swiperRef.current.swiper.on('slideChange', (e) => {
      setCurrentSlide(e.realIndex);
    });
  }, [swiperRef]);

  useEffect(() => {
    if (!swiperRef) {
      return;
    }
    if (!visibleEcoCenter && largerSm) {
      swiperRef.current.swiper.slideTo(1);
    }
  }, [visibleEcoCenter]);

  return (
    <>
      <Helmet>
        <title>Еко центр село Стіна</title>
        <meta
          name="description"
          content="Місія ЕКО-ЦЕНТРУ СТІНА» слугувати майданчиком співпраці різномантіних суб’єктів, що зацікавлені у практичній реалізації ідей сталого розвитку, обміні та збереженні сільської культури, а також реалізації сільських ініціатив."
        />
        <meta name="og:title" content="Еко центр село Стіна" />
        <meta
          name="og:description"
          content="Місія ЕКО-ЦЕНТРУ СТІНА» слугувати майданчиком співпраці різномантіних суб’єктів, що зацікавлені у практичній реалізації ідей сталого розвитку, обміні та збереженні сільської культури, а також реалізації сільських ініціатив."
        />
        <meta
          name="image"
          content="https://stina.pangeya.org.ua/static/media/logo.a06c2ab9.png"
        />
        <meta
          property="og:image"
          content="https://stina.pangeya.org.ua/static/media/logo.a06c2ab9.png"
        />
      </Helmet>
      <ScrollToTop />
      {largerSm ? <HeaderLight /> : <MobileHeader />}
      <PageContainer>
        <TopContainer>
          <TopContainerTextSection>
            <Box
              marginTop="5px"
              marginBottom={largerSm ? '10px' : '40px'}
              textAlign={largerSm ? 'start' : 'center'}
            >
              <Body1Bold>
                <span style={{ fontWeight: 900 }}>{strings.mission1}</span>
                {strings.mission2}
              </Body1Bold>
            </Box>
            <Box marginBottom="5px" textAlign={largerSm ? 'start' : 'center'}>
              {sortedContacts.map((contact) => (
                <Box marginBottom="8px" key={contact.name}>
                  <Body2>{contact.name}</Body2>
                </Box>
              ))}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={largerSm ? 'flex-start' : 'center'}
            >
              <SocialLinks />
            </Box>
          </TopContainerTextSection>
          <TopContainerImageSection>
            <TopImageContainer>
              <Image src={topImage} />
            </TopImageContainer>
            <Box
              position="absolute"
              right="50px"
              bottom={largerSm ? '15px' : '-15px'}
            >
              <StyledLink href={t(linkToPresentation)} target="_blank">
                <BlackEcoButton>
                  <Body2>{strings.download}</Body2>
                  <Box width="10px" marginLeft="8px">
                    <Image src={loadIcon} />
                  </Box>
                </BlackEcoButton>
              </StyledLink>
            </Box>
          </TopContainerImageSection>
        </TopContainer>
        <Box marginBottom="25px" marginTop={largerSm ? '25px' : '45px'}>
          <Image src={largerSm ? stripeImage : stripeImageMobile} />
        </Box>

        <MiddleContainer>
          <Box width={largerMd ? '65%' : '100%'}>
            <Image src={hataImage} />
          </Box>
          <MiddleContainerText>
            <Box marginBottom="50px" textAlign={largerSm ? 'start' : 'center'}>
              <Body2>{strings.founded}</Body2>
            </Box>
            <Box textAlign={largerSm ? 'start' : 'center'}>
              <Box marginBottom="15px">
                <Body1Bold>
                  {strings.listHead1}
                  <span style={{ fontWeight: 600 }}>{strings.listHead2}</span>
                </Body1Bold>
              </Box>
              <Box marginBottom="15px">
                <Body1Bold>&#8226; {strings.listItem1}</Body1Bold>
              </Box>
              <Box marginBottom="15px">
                <Body1Bold>&#8226; {strings.listItem2}</Body1Bold>
              </Box>
              <Box marginBottom="15px">
                <Body1Bold>&#8226; {strings.listItem3}</Body1Bold>
              </Box>
            </Box>
          </MiddleContainerText>
        </MiddleContainer>

        <Box width="100%" textAlign="center" marginTop="50px">
          <HeadingH5>{strings.buttonsHead}</HeadingH5>
        </Box>
        {largerSm ? (
          <ButtonsContainer>
            <StyledSliderButton
              active={currentSlide === 0}
              onClick={() => {
                swiperRef.current.swiper.slideTo(1);
              }}
            >
              <HeadingH6Bold>{strings.button0}</HeadingH6Bold>
            </StyledSliderButton>
            <StyledSliderButton
              active={currentSlide === 1}
              onClick={() => {
                swiperRef.current.swiper.slideTo(2);
              }}
            >
              <HeadingH6Bold>{strings.button1}</HeadingH6Bold>
            </StyledSliderButton>
            <StyledSliderButton
              active={currentSlide === 2}
              onClick={() => {
                swiperRef.current.swiper.slideTo(3);
              }}
            >
              <HeadingH6Bold>{strings.button2}</HeadingH6Bold>
            </StyledSliderButton>
            <StyledSliderButton
              active={currentSlide === 3}
              onClick={() => {
                swiperRef.current.swiper.slideTo(4);
              }}
            >
              <HeadingH6Bold>{strings.button3}</HeadingH6Bold>
            </StyledSliderButton>
            <StyledSliderButton
              active={currentSlide === 4}
              onClick={() => {
                swiperRef.current.swiper.slideTo(5);
              }}
            >
              <HeadingH6Bold>{strings.button4}</HeadingH6Bold>
            </StyledSliderButton>
          </ButtonsContainer>
        ) : (
          <ButtonsSliderMobile
            outerSlider={swiperRef.current}
            buttons={[
              strings.button0,
              strings.button1,
              strings.button2,
              strings.button3,
              strings.button4,
            ]}
          />
        )}
        {largerSm ? (
          <SwiperContainer>
            <Swiper loop slidesPerView={1} ref={swiperRef}>
              {slides.map((slide) => (
                <SwiperSlide key={slide.id}>
                  <MozaicSlideContainer>
                    <Image src={slide.src} />
                    <StyledCollapse in={slide.id === currentSlide}>
                      <StyledCollapseBody>
                        <Subtitle1Bold>{slide.text}</Subtitle1Bold>
                      </StyledCollapseBody>
                    </StyledCollapse>
                  </MozaicSlideContainer>
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperContainer>
        ) : (
          <SwiperContainer>
            <Swiper
              loop
              slidesPerView={1}
              ref={swiperRef}
              allowTouchMove={false}
            >
              {slidesMobile.map((slide) => (
                <SwiperSlide key={slide.id}>
                  <MobileGallery images={slide.images} text={slide.text} />
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperContainer>
        )}
        <TeamHeadingContainer>
          <HeadingH4>{strings.teamHead}</HeadingH4>
        </TeamHeadingContainer>
        <PeopleContainer>
          {teamState.members.map((person) => (
            <Box
              key={person.id}
              display="flex"
              flexDirection="column"
              width={largerSm ? '33%' : '50%'}
              alignItems="center"
              paddingTop="10px"
            >
              <PersonImage src={person.imageUrl} />
              <Box textAlign="center">
                <Box marginTop="10px" width={largerSm ? '100%' : '150px'}>
                  <HeadingH5>{t(person.name)}</HeadingH5>
                </Box>
                <Box width={largerSm ? '100%' : '150px'}>
                  <Body2>{t(person.position)}</Body2>
                </Box>
              </Box>
            </Box>
          ))}
        </PeopleContainer>
        <VideosSlider />
      </PageContainer>
    </>
  );
}

export default EcoCenter;
