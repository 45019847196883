import React, { useReducer } from 'react';
import scrollReducer, { initialState } from './reducer';
import { ScrollStateContext, ScrollDispatchContext } from './Context';

function ScrollProvider({ children }) {
  const [state, dispatch] = useReducer(scrollReducer, initialState);

  return (
    <ScrollStateContext.Provider value={state}>
      <ScrollDispatchContext.Provider value={dispatch}>
        {children}
      </ScrollDispatchContext.Provider>
    </ScrollStateContext.Provider>
  );
}

export default ScrollProvider;
