import * as actionTypes from './actionTypes';

const initialState = {
  articles: [],
  drafts: [],
  loadingArticles: true,
  loadingDrafts: true,
  errorArticles: null,
  errorDrafts: null,
  successMessage: '',
};

function articlesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SUCCESS_ARTICLE: {
      return {
        ...state,
        articles: action.data,
        loadingArticles: false,
        errorArticles: null,
      };
    }
    case actionTypes.SUCCESS_DRAFT: {
      return {
        ...state,
        drafts: action.data,
        loadingDrafts: false,
        errorDrafts: null,
      };
    }
    case actionTypes.ADD_ARTICLE: {
      return {
        ...state,
        articles: [action.data, ...state.articles],
        successMessage: 'Article successfully added',
      };
    }
    case actionTypes.ADD_DRAFT: {
      return {
        ...state,
        drafts: [action.data, ...state.drafts],
        successMessage: 'Article successfully added',
      };
    }
    case actionTypes.EDIT_ARTICLE: {
      return {
        ...state,
        successMessage: 'Article successfully updated',
        articles: state.articles.map((article) => {
          if (article.id === action.data.id) {
            return action.data;
          }
          return article;
        }),
      };
    }
    case actionTypes.EDIT_DRAFT: {
      return {
        ...state,
        successMessage: 'Article successfully updated',
        drafts: state.drafts.map((draft) => {
          if (draft.id === action.data.id) {
            return action.data;
          }
          return draft;
        }),
      };
    }
    case actionTypes.DELETE_ARTICLE: {
      return {
        ...state,
        successMessage: 'Article successfully removed',
        articles: state.articles.filter(
          (article) => article.id !== action.data,
        ),
      };
    }
    case actionTypes.DELETE_DRAFT: {
      return {
        ...state,
        successMessage: 'Article successfully removed',
        drafts: state.drafts.filter((draft) => draft.id !== action.data),
      };
    }
    case actionTypes.ERROR_ARTICLE: {
      return {
        ...state,
        loadingArticles: false,
        errorArticles: action.error,
      };
    }
    case actionTypes.ERROR_DRAFT: {
      return {
        ...state,
        loadingDrafts: false,
        errorDrafts: action.error,
      };
    }
    case actionTypes.REMOVE_MESSAGE: {
      return {
        ...state,
        successMessage: '',
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default articlesReducer;

export { initialState };
