import React from 'react';
import { useVideosState } from 'components/useVideos';
import Slider from './Slider';
import { VideoSliderContainer } from './style';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

const sortedVideos = (videos) =>
  videos
    .filter((video) => video.visible)
    .sort((a, b) => Number(a.sequence) - Number(b.sequence));

function VideosSlider() {
  const { videos } = useVideosState();

  return (
    <VideoSliderContainer>
      {videos.length > 0 && <Slider videos={sortedVideos(videos)} />}
    </VideoSliderContainer>
  );
}

export default VideosSlider;
