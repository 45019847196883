import React from 'react';
import YouTube from 'react-youtube';
import { HeadingH4, Body1, Body2 } from 'components/Typography';
import { extractYoutubeVideoId } from 'helpers/videoHelpers';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import useTranslation from 'hooks/useTranslation';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import {
  MainContainer,
  StyledImage,
  MediaContainer,
  FullWidthHeading,
} from './style';

const HtmlToReactParser = require('html-to-react').Parser;

const htmlToReactParser = new HtmlToReactParser();

function Article({ articleData = {} }) {
  const theme = useTheme();
  const largerSm = useMediaQuery(theme.breakpoints.up('sm'));

  const opts = {
    height: largerSm ? '480' : '240',
    width: largerSm ? '854' : '350',
  };

  const optsForHalfWidth = {
    height: largerSm ? '290' : '240',
    width: largerSm ? '457' : '350',
  };

  const t = useTranslation();

  const getReactComponentFromEditorState = (value) => {
    if (!value) {
      return '';
    }

    const state = EditorState.createWithContent(
      convertFromRaw(JSON.parse(value)),
    );
    const inHtml = draftToHtml(convertToRaw(state.getCurrentContent()));
    const inReact = htmlToReactParser.parse(inHtml);
    return inReact;
  };

  const renderArticleBlock = (block) => {
    switch (block.type) {
      case 'text':
        return (
          <Box
            width={block.rowWidth && largerSm ? block.rowWidth.value : '100%'}
            marginBottom="5px"
            marginTop="5px"
          >
            <Body1>{getReactComponentFromEditorState(t(block.value))}</Body1>
          </Box>
        );
      case 'image':
        return (
          <MediaContainer
            rowWidth={
              block.rowWidth && largerSm ? block.rowWidth.value : '100%'
            }
          >
            <StyledImage src={block.value} width={block.imageWidth} />
            <Box width="100%">
              <Body2>
                {getReactComponentFromEditorState(t(block.caption))}
              </Body2>
            </Box>
          </MediaContainer>
        );
      case 'video':
        return (
          <MediaContainer
            rowWidth={
              block.rowWidth && largerSm ? block.rowWidth.value : '100%'
            }
          >
            <YouTube
              videoId={extractYoutubeVideoId(block.value)}
              opts={block.rowWidth.value === '50%' ? optsForHalfWidth : opts}
            />
          </MediaContainer>
        );
      default:
        return <></>;
    }
  };

  return (
    <MainContainer>
      <Box
        display="flex"
        justifyContent={largerSm ? 'flex-start' : 'center'}
        width="100%"
        marginTop="20px"
      >
        <FullWidthHeading>
          {getReactComponentFromEditorState(t(articleData.header))}
        </FullWidthHeading>
      </Box>
      <Box display="flex" flexWrap="wrap">
        {articleData.blocks?.map((block) => renderArticleBlock(block))}
      </Box>
    </MainContainer>
  );
}

export default Article;
