import styled from 'styled-components';

export const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(21, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-gap: 5px;
`;

export const GridItem1 = styled.div`
	grid-column-start: 1;
	grid-column-end: 3;
	grid-row-start: 1;
	grid-row-end: 9;
`;

export const GridItem2 = styled.div`
	grid-column-start: 3;
	grid-column-end: 8;
	grid-row-start: 1;
	grid-row-end: 3;
`;

export const GridItem3 = styled.div`
	grid-column-start: 3;
	grid-column-end: 8;
	grid-row-start: 3;
	grid-row-end: 7;
`;

export const GridItem4 = styled.div`
	grid-column-start: 3;
	grid-column-end: 8;
	grid-row-start: 7;
	grid-row-end: 9;
`;

export const GridItem5 = styled.div`
	grid-column-start: 8;
	grid-column-end: 16;
	grid-row-start: 1;
	grid-row-end: 6;
`;

export const GridItem6 = styled.div`
	grid-column-start: 8;
	grid-column-end: 16;
	grid-row-start: 6;
	grid-row-end: 9;
`;

export const GridItem7 = styled.div`
	grid-column-start: 16;
	grid-column-end: 22;
	grid-row-start: 1;
	grid-row-end: 5;
`;

export const GridItem8 = styled.div`
	grid-column-start: 16;
	grid-column-end: 22;
	grid-row-start: 5;
	grid-row-end: 9;
`;

export const Image = styled.img`
	width: 100%;
	height: 100%;
  object-fit: cover;
`;
