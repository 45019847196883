import React from 'react';
import {
  Subtitle1Bold,
} from 'components/Typography';
import Box from '@material-ui/core/Box';

import {
  StyledImage,
} from './style';

function MobileGallery({images, text}) {

  return (
    <>
			<StyledImage src={images[0]}/>
			<StyledImage src={images[1]}/>
			<Box padding="10px 15px">
				<Box textAlign="center">
					<Subtitle1Bold>{text}</Subtitle1Bold>
				</Box>
			</Box>
			<StyledImage src={images[2]}/>
			<StyledImage src={images[3]}/>
			<StyledImage src={images[4]}/>
			<StyledImage src={images[5]}/>
			<StyledImage src={images[6]}/>
    </>
  );
}

export default MobileGallery;
