import styled from 'styled-components';
import * as colors from 'theme/colors';

export const FormContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

export const TeamContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-right: 10px;
  align-items: center;
`;

export const MemberImage = styled.div`
  ${({ imageUrl }) => `
    background: ${colors.CadetGrey};
    background-image: url(${imageUrl});
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    width: 200px;
    height: 200px;
  `}
`;

export const Line = styled.div`
  border-bottom: 1px solid black;
`;
