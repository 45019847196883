import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { SequenceFormContainer } from './style';

function SequenceForm({ video, onVideoChange }) {
  const [showForm, setShowForm] = useState(false);
  const [formValue, setFormValue] = useState(
    video.sequence ? video.sequence : '',
  );
  const [isLoading, setIsLoading] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleChangeVideo = async () => {
    const updated = {
      ...video,
      sequence: formValue,
    };

    setIsLoading(true);
    await onVideoChange(updated);
    setIsLoading(false);
    toggleForm();
  };

  return (
    <SequenceFormContainer>
      {showForm ? (
        <>
          <Box width="30%" marginRight="10px">
            <TextField
              value={formValue}
              onChange={(e) => setFormValue(e.target.value)}
            />
          </Box>
          <Box marginRight="5px">
            <Button
              type="button"
              onClick={handleChangeVideo}
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Save
            </Button>
          </Box>
          <Button
            type="button"
            onClick={toggleForm}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Box marginRight="10px">{`Sequence: ${
            video.sequence ? video.sequence : '0'
          }`}</Box>
          <Button
            type="button"
            onClick={toggleForm}
            variant="contained"
            color="primary"
          >
            Change
          </Button>
        </>
      )}
    </SequenceFormContainer>
  );
}

export default SequenceForm;
