import styled from 'styled-components';
import * as colors from 'theme/colors';
import Box from '@material-ui/core/Box';

export const PageContainer = styled.div`
  padding-top: 90px;
`;

export const CategoryContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    margin: 10px 0;

    ${theme.breakpoints.down("sm")} {
      flex-direction: column-reverse;
    }
  `}
`;

export const CategoryImage = styled.img`
  width: 100%;
  height: 'auto';
  display: block;
`;

export const BottomContainer = styled.div`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 30px;

    ${theme.breakpoints.down("sm")} {
      grid-template-columns: 1fr 1fr;
    }
  `}
`;

export const ArrowImageLeft = styled.img`
  width: 90px;
  height: auto;
  cursor: pointer;
  opacity: 0.6;
`;

export const ArrowImageRight = styled.img`
  width: 90px;
  height: auto;
  transform: rotate(180deg);
  cursor: pointer;
  opacity: 0.6;
`;

export const StyledSwiperSlideInnerContainer = styled.div`
  ${({ theme }) => `
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;

    ${theme.breakpoints.down("sm")} {
      width: 150px;
      height: 150px;
    }
  `}
`;

export const CategoryHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  border-top: 1px solid lightGrey;
  position: relative;
  background: ${colors.lightBlue};

  &:hover {
    color: white;
  }
`;

export const AboutContainer = styled.div`
  ${({ theme }) => `
    display: flex;

    ${theme.breakpoints.down("sm")} {
      flex-direction: column;
    }
  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 30px 30px 30px;
`;

export const StyledSliderButton = styled.div`
  ${({ theme, ...props }) => `
    color: ${props.active ? 'black' : 'grey'};
    text-decoration: ${props.active ? 'underline' : 'none'};
    margin-top: 20px;
    cursor: pointer;
    width: 18%;
    text-align: center;

    ${theme.breakpoints.down("sm")} {

    }
  `}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
