import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import CustomTextField from 'components/CustomTextField';
import {
  FacebookShareButton,
  TelegramShareButton,
  ViberShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TelegramIcon,
  ViberIcon,
  WhatsappIcon,
} from 'react-share';
import {
  StyledModal,
  ModalBody,
  ShareContainer,
  CopySuccessMessage,
  TextFieldBox,
  CopyButton,
  ShareTopic,
} from './style';

const logoUrl = 'https://stina.pangeya.org.ua/static/media/logo.a06c2ab9.png';

const SharingNetworkModal = ({ open, onClose }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(
      () => {
        setCopySuccess('URL скопійовано!');
      },
      () => {
        setCopySuccess('Не вдалося скопіювати URL');
      },
    );
  };

  return (
    <>
      <StyledModal open={open} onClose={onClose}>
        <ModalBody>
          <Formik
            validateOnChange
            initialValues={{
              name: url,
            }}
          >
            <Form>
              <ShareTopic>Поділитися:</ShareTopic>
              <ShareContainer>
                <FacebookShareButton url={url} media={logoUrl}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TelegramShareButton url={url} media={logoUrl}>
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
                <ViberShareButton url={url} media={logoUrl}>
                  <ViberIcon size={32} round />
                </ViberShareButton>
                <WhatsappShareButton url={url} media={logoUrl}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </ShareContainer>
              <TextFieldBox>
                <CustomTextField
                  name="name"
                  type="text"
                  fullWidth
                  disabled
                  inputProps={{
                    style: { color: 'black', cursor: 'text' },
                  }}
                />
                <CopyButton
                  onClick={handleCopy}
                  variant="contained"
                  color="primary"
                >
                  Копіювати
                </CopyButton>
              </TextFieldBox>
              {copySuccess && (
                <CopySuccessMessage>{copySuccess}</CopySuccessMessage>
              )}
            </Form>
          </Formik>
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default SharingNetworkModal;
