import React from 'react';
import { Helmet } from 'react-helmet';
import HeaderLight from 'components/HeaderLight';
import SocialLinks from 'components/SocialLinks';
import MobileHeader from 'components/Mobile/Header';
import ScrollToTop from 'components/ScrollToTop';
import topImage from 'assets/howtogethere.png';
import { HeadingH5, Body2, Body1, Body1Bold } from 'components/Typography';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTransport from 'components/useTransport';
import useTranslation from 'hooks/useTranslation';
import { howtogetPage } from 'strings/howtoget';
import TransportSlider from './TransportSlider';
import Variant from './Variant';
import {
  PageContainer,
  TopContainer,
  TopContainerTextSection,
  TopContainerImageSection,
  TopImageContainer,
  Image,
  BottomContainer,
  BottomContainerMapSection,
  BottomContainerSliderSection,
} from './style';

function HowToGet() {
  const [{ localTransport, howToGetTransport }, dispatch] = useTransport();
  const t = useTranslation();
  const strings = t(howtogetPage);

  const theme = useTheme();
  const largerSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <PageContainer>
      <Helmet>
        <title>Як доїхати в Стіну</title>
        <meta
          name="description"
          content="Село Стіна, Томашпільська ТГ, Вінницька обл. 48.455419, 28.417462"
        />
        <meta name="og:title" content="Як доїхати в Стіну" />
        <meta
          name="og:description"
          content="Село Стіна, Томашпільська ТГ, Вінницька обл. 48.455419, 28.417462"
        />
        <meta
          name="image"
          content="https://stina.pangeya.org.ua/static/media/logo.a06c2ab9.png"
        />
        <meta
          property="og:image"
          content="https://stina.pangeya.org.ua/static/media/logo.a06c2ab9.png"
        />
      </Helmet>
      <ScrollToTop />
      {largerSm ? <HeaderLight /> : <MobileHeader />}
      <TopContainer>
        <TopContainerTextSection>
          <Box marginBottom="10px" textAlign={largerSm ? 'start' : 'center'}>
            <Body1Bold>
              <span style={{ fontWeight: 600 }}>{strings.address1}</span>
              {strings.address2}
            </Body1Bold>
          </Box>
          <Box marginBottom="10px" textAlign={largerSm ? 'start' : 'center'}>
            <Body2>{strings.coords}</Body2>
          </Box>
          <Box marginBottom="10px">
            <Body1Bold>{strings.header}</Body1Bold>
          </Box>
          {howToGetTransport.map((transport, index) => (
            <Variant transport={transport} index={index} key={transport.id} />
          ))}
          <Box
            display="flex"
            alignItems="center"
            justifyContent={largerSm ? 'flex-start' : 'center'}
          >
            <SocialLinks />
          </Box>
        </TopContainerTextSection>
        <TopContainerImageSection>
          <TopImageContainer>
            <Image src={topImage} />
          </TopImageContainer>
        </TopContainerImageSection>
      </TopContainer>
      <BottomContainer>
        <BottomContainerMapSection>
          <iframe
            title="stina-map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2706590.3937637242!2d28.1356469!3d48.5110994!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cd270188c5ddd1%3A0x8a04ff6d2e9246e4!2z0KHRgtGW0L3QsCwg0JLRltC90L3QuNGG0YzQutCwINC-0LHQu9Cw0YHRgtGMLCAyNDIzMQ!5e0!3m2!1suk!2sua!4v1648899347184!5m2!1suk!2sua"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          />
        </BottomContainerMapSection>
        <BottomContainerSliderSection>
          <Box
            height="5%"
            display="flex"
            alignItems="flex-start"
            justifyContent={largerSm ? 'flex-start' : 'center'}
            paddingLeft="20px"
          >
            <Body1Bold>{strings.local}</Body1Bold>
          </Box>
          <TransportSlider transports={localTransport} />
        </BottomContainerSliderSection>
      </BottomContainer>
    </PageContainer>
  );
}

export default HowToGet;
