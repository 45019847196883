import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Body1 } from 'components/Typography';
import useTranslation from 'hooks/useTranslation';
import { mainPage } from 'strings/mainPage';
import embroderyImage from 'assets/embrodery.jpg';
import embroderyImage2 from 'assets/embrodery2.jpg';
import embroderyImage3 from 'assets/embrodery3.jpg';
import embroderyImage4 from 'assets/embrodery4.jpg';
import embroderyImage5 from 'assets/embrodery5.jpg';
import embroderyImageMobile from 'assets/mobile/embrodery.jpg';
import embroderyImageMobile2 from 'assets/mobile/embrodery2.jpg';
import embroderyImageMobile3 from 'assets/mobile/embrodery3.jpg';
import embroderyImageMobile4 from 'assets/mobile/embrodery4.jpg';
import embroderyImageMobile5 from 'assets/mobile/embrodery5.jpg';
import SwiperCore, { Autoplay } from 'swiper';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';

import {
  SwiperContainer,
  ImageContainer,
  StyledImage,
  LeftSideTextContent,
  SliderButtonsContainer,
  SwiperPaginationButton,
  MiddleTextContent
} from './style';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

const slides = [
  { id: 0, src: embroderyImage },
  { id: 1, src: embroderyImage2 },
  { id: 2, src: embroderyImage3 },
  { id: 3, src: embroderyImage4 },
  { id: 4, src: embroderyImage5 },
];

const slidesMobile = [
  { id: 0, src: embroderyImageMobile },
  { id: 1, src: embroderyImageMobile2 },
  { id: 2, src: embroderyImageMobile3 },
  { id: 3, src: embroderyImageMobile4 },
  { id: 4, src: embroderyImageMobile5 },
];

function MiddleSlider() {
  SwiperCore.use([Autoplay]);

  const t = useTranslation();
  const strings = t(mainPage);

  const swiperRef = useRef(null);

  const defaultSlideIndex = 0;
  const [currentSlide, setCurrentSlide] = useState(defaultSlideIndex);

  const theme = useTheme();
  const largerSm = useMediaQuery(theme.breakpoints.up('sm'));

  const swiperSlides = largerSm ? slides : slidesMobile

  useEffect(() => {
    if (!swiperRef) {
      return;
    }
    swiperRef.current.swiper.on('slideChange', (e) => {
      setCurrentSlide(e.realIndex);
    });
  }, [swiperRef]);

  return (
    <>
      {!largerSm &&
        <MiddleTextContent>
          <Box textAlign={largerSm ? "start" : "center"}>
            <Body1>
              <i>{strings.embrText}</i>
            </Body1>
          </Box>
        </MiddleTextContent>
      }
      <SwiperContainer>
        <SliderButtonsContainer>
          {slides.map((slide) => (
            <SwiperPaginationButton
              key={slide.id}
              active={slide.id === currentSlide}
              onClick={() => {
                swiperRef.current.swiper.slideTo(slide.id + 1);
              }}
            />
          ))}
        </SliderButtonsContainer>
        {largerSm &&
          <LeftSideTextContent>
            <Body1>
              <i>{strings.embrText}</i>
            </Body1>
          </LeftSideTextContent>
        }

        <Swiper
          loop
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          ref={swiperRef}
          modules={[Autoplay]}
        >
          {swiperSlides.map((slide) => (
            <SwiperSlide key={slide.id}>
              <ImageContainer>
                <StyledImage src={slide.src} />
              </ImageContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainer>
    </>
  );
}

export default MiddleSlider;
