import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Body1 } from 'components/Typography';
import { setScrollToCategories, useScrollDispatch } from 'components/useScroll';
import { AppPath } from 'App';
import arrowImage from 'assets/arrow.png';
import Box from '@material-ui/core/Box';
import { useCategories } from './categories';

import {
  CategoryImage,
  SliderPrev,
  SliderNext,
  SwiperContainer,
  ArrowImageLeft,
  ArrowImageRight,
  SlideGrid,
  StyledMobileSlide,
} from './style';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

function CategoriesSliderMobile() {
  const slidesInGrid = 4;
  const start = 0;
  const end = slidesInGrid;
  const [startIndex, setStartIndex] = useState(start);
  const [endIndex, setEndIndex] = useState(end);
  const swiperRef = useRef(null);
  const categories = useCategories();
  const [calculatedCategories, setCalculatedCategories] = useState(
    categories.slice(start, end),
  );
  const history = useHistory();
  const scrollDispatch = useScrollDispatch();

  const calculateNextIndexes = () => {
    if (endIndex + slidesInGrid > categories.length) {
      const newEndIndex = endIndex + slidesInGrid - categories.length;
      setStartIndex(endIndex);
      setEndIndex(newEndIndex);
      return;
    }
    setStartIndex(endIndex);
    setEndIndex(endIndex + slidesInGrid);
  };

  const calculatePrevIndexes = () => {
    if (startIndex === 0) {
      setStartIndex(categories.length - slidesInGrid);
      setEndIndex(categories.length);
      return;
    }

    if (startIndex - slidesInGrid < 0) {
      const newStartIndex = categories.length + startIndex - slidesInGrid;

      setStartIndex(newStartIndex);
      setEndIndex(startIndex);
      return;
    }

    setStartIndex(startIndex - slidesInGrid);
    setEndIndex(startIndex);
  };

  useEffect(() => {
    if (startIndex + slidesInGrid > categories.length) {
      setCalculatedCategories([
        ...categories.slice(startIndex, categories.length),
        ...categories.slice(start, endIndex),
      ]);
      return;
    }

    if (startIndex > endIndex) {
      setCalculatedCategories([
        ...categories.slice(startIndex, categories.length),
        ...categories.slice(start, endIndex),
      ]);
      return;
    }

    setCalculatedCategories(categories.slice(startIndex, endIndex));
  }, [endIndex]);

  return (
    <SwiperContainer>
      <SliderPrev>
        <ArrowImageLeft
          src={arrowImage}
          onClick={() => {
            swiperRef.current.swiper.slidePrev();
            calculatePrevIndexes();
          }}
        />
      </SliderPrev>
      <Swiper loop slidesPerView={1} ref={swiperRef}>
        <SwiperSlide>
          <SlideGrid>
            {calculatedCategories.map((cat) => (
              <StyledMobileSlide
                onClick={() => {
                  setScrollToCategories(scrollDispatch, true);
                  history.push(`${AppPath.CATEGORY}/${cat.textId}`);
                }}
                key={cat.id}
              >
                <CategoryImage src={cat.image} />
                <Box textAlign="center" width="80%">
                  <Body1>{cat.name}</Body1>
                </Box>
              </StyledMobileSlide>
            ))}
          </SlideGrid>
        </SwiperSlide>
      </Swiper>
      <SliderNext>
        <ArrowImageRight
          src={arrowImage}
          onClick={() => {
            swiperRef.current.swiper.slideNext();
            calculateNextIndexes();
          }}
        />
      </SliderNext>
    </SwiperContainer>
  );
}

export default CategoriesSliderMobile;
