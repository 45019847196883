import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// Styled Modal component
export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
`;

// Modal body styles
export const ModalBody = styled(Box)`
  position: relative;
  background-color: #fff;
  outline: none;
  width: 300px;
  border-radius: 10px;
  box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.25);
  padding: 16px;
  color: #000;
  font-family: 'Montserrat', sans-serif !important;
`;

// Share container styles
export const ShareContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

// Success message styles
export const CopySuccessMessage = styled.div`
  margin-top: 8px;
  color: green;
`;

// TextField container styles
export const TextFieldBox = styled(Box)`
  margin-top: 16px;
  display: flex;
  align-items: center;
`;

// Copy button styles
export const CopyButton = styled(Button)`
  margin-left: 8px !important;
`;

export const ShareTopic = styled.div`
  margin-bottom: 8px;
`;
