import styled from 'styled-components';

export const LanguageContainer = styled.div`
`;

export const FlagImage = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

export const FlagImageContainer = styled.div`
  display: inline-block;
  padding: 0 5px;
  border-right: ${props => props.last ? 'none' : '1px solid lightGrey'};
  opacity: ${props => props.selected ? 0.5 : 1};
`;
