import * as actionTypes from './actionTypes';

const initialState = {
  members: [],
  loading: true,
  error: null,
};

function teamReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SUCCESS: {
      return {
        ...state,
        members: action.data,
        loading: false,
        error: null,
      };
    }
    case actionTypes.ADD_MEMBER: {
      return {
        ...state,
        members: [action.data, ...state.members],
      };
    }
    case actionTypes.DELETE_MEMBER: {
      return {
        ...state,
        members: state.members.filter((member) => member.id !== action.data),
      };
    }
    case actionTypes.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default teamReducer;

export { initialState };
