import React, { useRef, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { AppPath } from 'App';
import { useHistory } from 'react-router-dom';
import CustomTextField from 'components/CustomTextField';
import { CommonEditor } from 'components/TextEditor';
import SelectInput from 'components/Select';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Box from '@material-ui/core/Box';
import { RoundIconButton } from 'components/Buttons';
import { HeadingH6 } from 'components/Typography/style';
import { generate } from 'shortid';
import { removeImageFromStore } from 'firebase';
import { useArticlesState } from 'components/useArticles';
import UploadFile from '../UploadFile';

import { FormContainer, ButtonBlockContainer, ButtonBlockBox } from './style';

const createValidationSchema = (articles) => {
  const validationSchema = yup.object().shape({
    categories: yup.array().min(1, 'Please select category'),
    thumb: yup.string().required('Please add thumb image'),
    slug: yup
      .string()
      .required('Please add article slug')
      .test('unique', 'Slug is already taken', function unique(value) {
        const article = articles.find((el) => el.slug === value);
        if (article) {
          return false;
        }
        return true;
      }),
    header: yup.object().shape({
      uk: yup.string().required('Please add ukrainian header'),
      en: yup.string().required('Please add english header'),
    }),
  });

  return validationSchema;
};

function getCategoriesOptions(categories) {
  return categories.map((category) => ({
    label: category.name,
    value: category.id,
  }));
}

function AddArticleForm({
  onShowPreview,
  categories,
  onAddArticle,
  onAddDraft,
  onEditArticle,
  onEditDraft,
  onRemoveDraft,
  onRemoveArticle,
  article,
  draft,
}) {
  const { articles } = useArticlesState();
  const topContainerRef = useRef(null);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const history = useHistory();

  const handleFormSubmit = async (values, props, isDraft = false) => {
    const articleBody = {
      ...values,
      categories: values.categories.map((category) => category.value),
    };

    if (article) {
      if (isDraft) {
        await onAddDraft(articleBody);
        await onRemoveArticle(article, article.id);
        history.push(`${AppPath.GOSPODAR}/articles/drafts`);
      } else {
        await onEditArticle(articleBody, article.id);
        history.goBack();
      }
      props.resetForm();
      return;
    }

    if (draft) {
      if (isDraft) {
        await onEditDraft(articleBody, draft.id);
        history.goBack();
      } else {
        await onAddArticle(articleBody);
        await onRemoveDraft(draft, draft.id);
        history.push(`${AppPath.GOSPODAR}/articles/list`);
      }
      props.resetForm();
      return;
    }

    if (isDraft) {
      await onAddDraft(articleBody);
      history.push(`${AppPath.GOSPODAR}/articles/drafts`);
      props.resetForm();
    } else {
      await onAddArticle(articleBody);
      history.push(`${AppPath.GOSPODAR}/articles/list`);
      props.resetForm();
    }
  };

  const moveBlockUp = (blocks, blockId) => {
    const index = blocks.findIndex((el) => el.id === blockId);
    if (index > 0) {
      [blocks[index - 1], blocks[index]] = [blocks[index], blocks[index - 1]];
    }
    return blocks;
  };

  const moveBlockDown = (blocks, blockId) => {
    const index = blocks.findIndex((el) => el.id === blockId);
    if (index === -1 || index >= blocks.length - 1) {
      return blocks;
    }
    [blocks[index + 1], blocks[index]] = [blocks[index], blocks[index + 1]];
    return blocks;
  };

  const addBlockAfterCurrentIndex = (blocks, currentBlockId, newBlock) => {
    const currentIndex = blocks.findIndex((el) => el.id === currentBlockId);
    const newBlocks = [...blocks];
    newBlocks.splice(currentIndex + 1, 0, newBlock);
    return newBlocks;
  };

  const addBlockToStart = (blocks, newBlock) => {
    const newBlocks = [newBlock, ...blocks];
    return newBlocks;
  };

  const errorCheckHandler = (errors) => {
    if (Object.keys(errors).length > 0) {
      topContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const showPreview = (values) => {
    onShowPreview(values);
  };

  const data = draft || article || {};

  const initialValues = {
    categories: data.categories
      ? getCategoriesOptions(categories).filter((cat) =>
          data.categories.includes(cat.value),
        )
      : [],
    thumb: data.thumb || '',
    slug: data.slug || '',
    header: {
      uk: data.header?.uk || '',
      en: data.header?.en || '',
    },
    description: {
      uk: data.description?.uk || '',
      en: data.description?.en || '',
    },
    blocks: data.blocks || [],
  };

  return (
    <Box width="100%" ref={topContainerRef}>
      <Formik
        validateOnChange
        enableReinitialize
        initialValues={initialValues}
        validationSchema={createValidationSchema(
          article ? articles.filter((el) => article.id !== el.id) : articles,
        )}
        onSubmit={(values, { resetForm }) =>
          handleFormSubmit(values, { resetForm })
        }
      >
        {({ values, setValues, setFieldValue, errors, submitForm }) => (
          <Form>
            <FormContainer>
              <Box marginBottom="10px">
                <SelectInput
                  name="categories"
                  options={getCategoriesOptions(categories)}
                  setFieldValue={setFieldValue}
                  values={values}
                  placeholder="Categories"
                  isMulti
                />
              </Box>
              <Box marginBottom="20px">
                <CustomTextField label="Thumb image" name="thumb" type="text" />
                <UploadFile
                  setUrl={(option) => setFieldValue('thumb', option)}
                  prevUrl={values.thumb}
                />
              </Box>

              <Box marginBottom="10px">
                <CustomTextField label="Article slug" name="slug" type="text" />
              </Box>

              <Box marginBottom="10px" display="flex" width="100%">
                <Box width="44%" marginRight="5px">
                  <Box marginBottom="10px">
                    <HeadingH6>UA</HeadingH6>
                  </Box>
                  <CommonEditor
                    placeholder="Header"
                    name="header.uk"
                    setFieldValue={setFieldValue}
                    height="100px"
                    value={values.header.uk}
                  />
                </Box>
                <Box width="44%">
                  <Box marginBottom="10px">
                    <HeadingH6>EN</HeadingH6>
                  </Box>
                  <CommonEditor
                    placeholder="Header"
                    name="header.en"
                    setFieldValue={setFieldValue}
                    height="100px"
                    value={values.header.en}
                  />
                </Box>
              </Box>

              <Box marginBottom="10px" display="flex" width="100%">
                <Box width="44%" marginRight="5px">
                  <CustomTextField
                    label="Short description"
                    name="description.uk"
                    type="text"
                    multiline
                    rows={6}
                    rowsMax={6}
                  />
                </Box>
                <Box width="44%">
                  <CustomTextField
                    label="Short description"
                    name="description.en"
                    type="text"
                    multiline
                    rows={6}
                    rowsMax={6}
                  />
                </Box>
              </Box>

              <FieldArray name="blocks">
                {({ push }) => (
                  <>
                    <Box display="flex">
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          const newBlock = {
                            id: generate(),
                            type: 'text',
                            value: { uk: '', en: '' },
                            rowWidth: { value: '100%', label: '100%' },
                          };

                          const updatedBlocks = addBlockToStart(
                            values.blocks,
                            newBlock,
                          );

                          setValues({
                            ...values,
                            blocks: updatedBlocks,
                          });
                        }}
                      >
                        Add text
                      </Button>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          const newBlock = {
                            id: generate(),
                            type: 'video',
                            value: '',
                            rowWidth: { value: '100%', label: '100%' },
                          };

                          const updatedBlocks = addBlockToStart(
                            values.blocks,
                            newBlock,
                          );

                          setValues({
                            ...values,
                            blocks: updatedBlocks,
                          });
                        }}
                      >
                        Add video
                      </Button>
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          const newBlock = {
                            id: generate(),
                            type: 'image',
                            value: '',
                            caption: { uk: '', en: '' },
                            rowWidth: { value: '100%', label: '100%' },
                            imageWidth: '',
                          };

                          const updatedBlocks = addBlockToStart(
                            values.blocks,
                            newBlock,
                          );

                          setValues({
                            ...values,
                            blocks: updatedBlocks,
                          });
                        }}
                      >
                        Add image
                      </Button>
                    </Box>
                    {values.blocks.map((block, index) => (
                      <Box>
                        <Box
                          key={block.id}
                          marginY="20px"
                          display="flex"
                          padding="7px"
                          borderRadius="5px"
                          border="1px solid lightGrey"
                        >
                          {block.type === 'text' ? (
                            <Box
                              display="flex"
                              flexDirection="column"
                              width="100%"
                            >
                              <Box display="flex" width="100%">
                                <Box marginRight="5px" width="46.5%">
                                  <CommonEditor
                                    placeholder={block.type}
                                    name={`blocks[${index}].value.uk`}
                                    setFieldValue={setFieldValue}
                                    value={values.blocks[index].value.uk}
                                  />
                                </Box>
                                <Box width="46.5%">
                                  <CommonEditor
                                    placeholder={block.type}
                                    name={`blocks[${index}].value.en`}
                                    setFieldValue={setFieldValue}
                                    value={values.blocks[index].value.en}
                                  />
                                </Box>
                              </Box>
                              <Box
                                marginTop="10px"
                                width="25%"
                                display="flex"
                                alignItems="center"
                              >
                                <Box marginRight="10px">
                                  <HeadingH6>Row width:</HeadingH6>
                                </Box>
                                <SelectInput
                                  name={`blocks[${index}].rowWidth`}
                                  options={[
                                    { value: '100%', label: '100%' },
                                    { value: '50%', label: '50%' },
                                  ]}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  placeholder="100%"
                                  fieldArrayName={{
                                    field: 'blocks',
                                    index,
                                    name: 'rowWidth',
                                  }}
                                />
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              display="flex"
                              flexDirection="column"
                              width="100%"
                            >
                              <CustomTextField
                                label={block.type}
                                name={`blocks[${index}].value`}
                                type="text"
                              />
                              {block.type === 'image' && (
                                <>
                                  <UploadFile
                                    setUrl={(option) =>
                                      setFieldValue(
                                        `blocks[${index}].value`,
                                        option,
                                      )
                                    }
                                    prevUrl={values.blocks[index].value}
                                  />
                                  <Box marginTop="10px" marginBottom="5px">
                                    <HeadingH6>Image Caption:</HeadingH6>
                                  </Box>
                                  <Box display="flex" width="100%">
                                    <Box marginRight="5px" width="46.5%">
                                      <CommonEditor
                                        placeholder="image caption"
                                        name={`blocks[${index}].caption.uk`}
                                        setFieldValue={setFieldValue}
                                        height="100px"
                                        value={values.blocks[index].caption.uk}
                                      />
                                    </Box>
                                    <Box width="46.5%">
                                      <CommonEditor
                                        placeholder="image caption"
                                        name={`blocks[${index}].caption.en`}
                                        setFieldValue={setFieldValue}
                                        height="100px"
                                        value={values.blocks[index].caption.en}
                                      />
                                    </Box>
                                  </Box>
                                  <Box
                                    marginTop="10px"
                                    marginBottom="5px"
                                    width="30%"
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Box width="40%">
                                      <HeadingH6>Image Width %:</HeadingH6>
                                    </Box>
                                    <Box width="60%">
                                      <CustomTextField
                                        name={`blocks[${index}].imageWidth`}
                                        type="number"
                                      />
                                    </Box>
                                  </Box>
                                </>
                              )}
                              <Box
                                marginTop="10px"
                                width="40%"
                                display="flex"
                                alignItems="center"
                              >
                                <Box marginRight="10px">
                                  <HeadingH6>Row width:</HeadingH6>
                                </Box>
                                <SelectInput
                                  name={`blocks[${index}].rowWidth`}
                                  options={[
                                    { value: '100%', label: '100%' },
                                    { value: '50%', label: '50%' },
                                  ]}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  placeholder="100%"
                                  fieldArrayName={{
                                    field: 'blocks',
                                    index,
                                    name: 'rowWidth',
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                          <Box marginLeft="10px">
                            <ButtonBlockContainer>
                              <ButtonBlockBox>
                                <RoundIconButton
                                  type="button"
                                  onClick={() => {
                                    const newBlocks = values.blocks.filter(
                                      (el) => el.id !== block.id,
                                    );
                                    setValues({
                                      ...values,
                                      blocks: newBlocks,
                                    });
                                  }}
                                >
                                  <DeleteIcon />
                                </RoundIconButton>
                              </ButtonBlockBox>
                              <ButtonBlockBox>
                                {values.blocks.findIndex(
                                  (el) => el.id === block.id,
                                ) > 0 && (
                                  <RoundIconButton
                                    type="button"
                                    onClick={() => {
                                      const newBlocks = moveBlockUp(
                                        [...values.blocks],
                                        block.id,
                                      );

                                      setValues({
                                        ...values,
                                        blocks: newBlocks,
                                      });
                                    }}
                                  >
                                    <ArrowUpward />
                                  </RoundIconButton>
                                )}
                                {values.blocks.findIndex(
                                  (el) => el.id === block.id,
                                ) <
                                  values.blocks.length - 1 && (
                                  <RoundIconButton
                                    type="button"
                                    onClick={() => {
                                      const newBlocks = moveBlockDown(
                                        [...values.blocks],
                                        block.id,
                                      );

                                      setValues({
                                        ...values,
                                        blocks: newBlocks,
                                      });
                                    }}
                                  >
                                    <ArrowDownward />
                                  </RoundIconButton>
                                )}
                              </ButtonBlockBox>
                            </ButtonBlockContainer>
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Button
                            color="primary"
                            type="button"
                            onClick={() => {
                              const newBlock = {
                                id: generate(),
                                type: 'text',
                                value: { uk: '', en: '' },
                                rowWidth: { value: '100%', label: '100%' },
                              };

                              const updatedBlocks = addBlockAfterCurrentIndex(
                                values.blocks,
                                block.id,
                                newBlock,
                              );

                              setValues({
                                ...values,
                                blocks: updatedBlocks,
                              });
                            }}
                          >
                            Add text
                          </Button>
                          <Button
                            color="primary"
                            type="button"
                            onClick={() => {
                              const newBlock = {
                                id: generate(),
                                type: 'video',
                                value: '',
                                rowWidth: { value: '100%', label: '100%' },
                              };

                              const updatedBlocks = addBlockAfterCurrentIndex(
                                values.blocks,
                                block.id,
                                newBlock,
                              );

                              setValues({
                                ...values,
                                blocks: updatedBlocks,
                              });
                            }}
                          >
                            Add video
                          </Button>
                          <Button
                            color="primary"
                            type="button"
                            onClick={() => {
                              const newBlock = {
                                id: generate(),
                                type: 'image',
                                value: '',
                                caption: { uk: '', en: '' },
                                rowWidth: { value: '100%', label: '100%' },
                                imageWidth: '',
                              };

                              const updatedBlocks = addBlockAfterCurrentIndex(
                                values.blocks,
                                block.id,
                                newBlock,
                              );

                              setValues({
                                ...values,
                                blocks: updatedBlocks,
                              });
                            }}
                          >
                            Add image
                          </Button>
                        </Box>
                      </Box>
                    ))}
                  </>
                )}
              </FieldArray>
              <Box
                display="flex"
                justifyContent="space-between"
                marginTop="40px"
              >
                <RoundIconButton
                  type="submit"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    errorCheckHandler(errors);
                    handleFormSubmit(values, { resetForm: () => {} }, false);
                  }}
                >
                  <AddIcon />
                </RoundIconButton>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    handleFormSubmit(values, { resetForm: () => {} }, true);
                  }}
                >
                  Save as Draft
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={() => showPreview(values)}
                >
                  Preview
                </Button>
              </Box>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default AddArticleForm;
