export function populateCategoriesDrafts(categories, drafts = []) {
  const copyCategoriesDrafts = [...categories];

  drafts.forEach((draft) => {
    if (Array.isArray(draft.categories)) {
      draft.categories.forEach((categoryId) => {
        const category = copyCategoriesDrafts.find(
          (cat) => cat.id === categoryId,
        );
        if (category) {
          category.drafts.push(draft);
        }
      });
    }
  });
  return copyCategoriesDrafts;
}
