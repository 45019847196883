import React from 'react';
import { HeadingH5, Body2, Body1Bold } from 'components/Typography';
import useTranslation from 'hooks/useTranslation';
import { howtogetPage } from 'strings/howtoget';

import { StyledButton, TextContainer } from './style';

function Variant({ transport, index }) {
  const t = useTranslation();
  const strings = t(howtogetPage);

  return (
    <>
      <StyledButton>
        <Body1Bold>{`${strings.variant} ${index + 1}`}</Body1Bold>
      </StyledButton>
      <TextContainer>
        <HeadingH5>{t(transport.header)}</HeadingH5>
        <Body2>{t(transport.description)}</Body2>
      </TextContainer>
    </>
  );
}

export default Variant;
