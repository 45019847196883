import Image1 from 'assets/mobile/gallery1/1.png';
import Image2 from 'assets/mobile/gallery1/2.png';
import Image3 from 'assets/mobile/gallery1/3.png';
import Image4 from 'assets/mobile/gallery1/4.png';
import Image5 from 'assets/mobile/gallery1/5.png';
import Image6 from 'assets/mobile/gallery1/6.png';
import Image7 from 'assets/mobile/gallery1/7.png';

import Image9 from 'assets/mobile/gallery2/1.png';
import Image10 from 'assets/mobile/gallery2/2.png';
import Image11 from 'assets/mobile/gallery2/3.png';
import Image12 from 'assets/mobile/gallery2/4.png';
import Image13 from 'assets/mobile/gallery2/5.JPG';
import Image14 from 'assets/mobile/gallery2/6.png';
import Image15 from 'assets/mobile/gallery2/7.png';

import Image30 from 'assets/mobile/gallery3/1.jpg';
import Image32 from 'assets/mobile/gallery3/3.jpg';
import Image33 from 'assets/mobile/gallery3/4.jpg';
import Image34 from 'assets/mobile/gallery3/5.jpg';
import Image35 from 'assets/mobile/gallery3/6.jpg';
import Image36 from 'assets/mobile/gallery3/7.jpg';
import Image37 from 'assets/mobile/gallery3/2.jpg';

import Image16 from 'assets/mobile/gallery4/1.png';
import Image17 from 'assets/mobile/gallery4/2.png';
import Image18 from 'assets/mobile/gallery4/3.png';
import Image19 from 'assets/mobile/gallery4/4.png';
import Image20 from 'assets/mobile/gallery4/5.png';
import Image21 from 'assets/mobile/gallery4/6.png';
import Image22 from 'assets/mobile/gallery4/7.png';

import Image23 from 'assets/mobile/gallery5/1.png';
import Image24 from 'assets/mobile/gallery5/2.png';
import Image25 from 'assets/mobile/gallery5/3.png';
import Image26 from 'assets/mobile/gallery5/4.png';
import Image27 from 'assets/mobile/gallery5/5.png';
import Image28 from 'assets/mobile/gallery5/6.png';
import Image29 from 'assets/mobile/gallery5/7.png';

import useTranslation from 'hooks/useTranslation';
import { ecoCenter } from 'strings/ecoCenter';


const imagesSlide0 = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];
const imagesSlide1 = [Image9, Image10, Image11, Image12, Image13, Image14, Image15];
const imagesSlide2 = [Image30, Image32, Image33, Image34, Image35, Image36, Image37];
const imagesSlide3 = [Image16, Image17, Image18, Image19, Image20, Image21, Image22];
const imagesSlide4 = [Image24, Image25, Image26, Image27, Image28, Image29, Image23];

const useSlidesMobile = () => {
  const t = useTranslation();
  const strings = t(ecoCenter);
  return [
    {
      id: 0,
      images: imagesSlide0,
      text: strings.Slide0,
    },
    {
      id: 1,
      images: imagesSlide1,
      text: strings.Slide1,
    },
    {
      id: 2,
      images: imagesSlide2,
      text: strings.Slide2,
    },
    {
      id: 3,
      images: imagesSlide3,
      text: strings.Slide3,
    },
    {
      id: 4,
      images: imagesSlide4,
      text: strings.Slide4,
    },
  ];
};

export default useSlidesMobile;
