import styled from 'styled-components';
import * as colors from 'theme/colors';
import Link from '@material-ui/core/Link';

export const PageContainer = styled.div`
  padding-top: 90px;
`;

export const TopContainer = styled.div`
  ${({ theme }) => `
    display: flex;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `}
`;

export const TopContainerTextSection = styled.div`
  ${({ theme }) => `
    width: 40%;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      padding: 0 20px;
    }
  `}
`;

export const TopContainerImageSection = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 60%;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const TopImageContainer = styled.div``;

export const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

export const MiddleContainer = styled.div`
  ${({ theme }) => `
    position: relative;
    display: flex;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column-reverse;
    }
  `}
`;

export const MiddleContainerText = styled.div`
  ${({ theme }) => `
    width: 35%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 50px 30px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const MozaicSlideContainer = styled.div``;

export const SliderPrev = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;
`;

export const SliderNext = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;
`;

export const ArrowImageLeft = styled.img`
  ${({ theme }) => `
    width: 90px;
    height: 120px;
    cursor: pointer;
    opacity: 0.6;

    ${theme.breakpoints.down('sm')} {
      width: 24px;
      height: 24px;
    }
  `}
`;

export const ArrowImageRight = styled.img`
  ${({ theme }) => `
    width: 90px;
    height: 120px;
    transform: rotate(180deg);
    cursor: pointer;
    opacity: 0.6;

  ${theme.breakpoints.down('sm')} {
      width: 24px;
      height: 24px;
    }
  `}
`;

export const SwiperContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;

export const PeopleContainer = styled.div`
  ${({ theme }) => `
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 100px;

  ${theme.breakpoints.down('sm')} {
    padding: 10px;
    }
  `}
`;

export const PersonImage = styled.img`
  width: 70%;
  height: auto;
  display: block;
  border-radius: 50%;
`;

export const StyledCollapse = styled.div`
  ${({ theme, ...props }) => `
    position: absolute;
    transform: translatey(-30%);
    top: 50%;
    left: 0;
    background: transparent;
    width: ${props.in ? '700px' : '0'};
    height: 400px;
    transition: width 1s ease 0.3s;
    z-index: 100;
    overflow: hidden;
  `}
`;

export const StyledCollapseBody = styled.div`
  width: 700px;
  padding: 40px;
  box-sizing: border-box;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: rgba(255, 255, 255, 0.8);
`;

export const TeamHeadingContainer = styled.div`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: 30px;
    text-transform: uppercase;
    box-sizing: border-box;

    ${theme.breakpoints.down('sm')} {
      margin-top: 0px;
    }

  `}
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

export const StyledSliderButton = styled.div`
  ${({ theme, ...props }) => `
    color: ${props.active ? 'black' : 'grey'};
    text-decoration: ${props.active ? 'underline' : 'none'};
    margin-right: 20px;
    cursor: pointer;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      color: black;
      text-align: center;
    }
  `}
`;

export const TextContainerTop = styled.div`
  padding-left: 20px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none !important;
  color: black !important;
  display: flex;
`;

export const BlackEcoButton = styled.div`
  background: #030303;
  color: white;
  padding: 10px 20px;
  border-radius: 52px;
  text-transform: uppercase;
  display: flex;
  align-items: baseline;
`;
