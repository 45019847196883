import React, { useReducer } from 'react';
import teamReducer, { initialState } from './reducer';
import { TeamStateContext, TeamDispatchContext } from './Context';

function TeamProvider({ children }) {
  const [state, dispatch] = useReducer(teamReducer, initialState);

  return (
    <TeamStateContext.Provider value={state}>
      <TeamDispatchContext.Provider value={dispatch}>
        {children}
      </TeamDispatchContext.Provider>
    </TeamStateContext.Provider>
  );
}

export default TeamProvider;
